<!-- Contact Us Start -->
<section class="section bg-light" id="contact" style="padding-top: 10px">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Contact Us</h3>
          <p class="text-muted font-size-15">Shoot us a message any time at the email address below!</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="mail"></i-feather> : <span>info@votercertified.com</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
