<!-- Features Start -->
<section class="section feather-bg-img" id="about" style="background: #fff">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 offset-lg-1">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Unbiased Break</p>
        <h6 class="font-weight-semibold line-height-1_4 mb-4">
          Unbiased Break is all you need for your news briefing needs.
        </h6>
        <p class="text-muted font-size-15 mb-4">
          The days of scrolling past articles you're
          uninterested in are gone. Get ready to focus on relevant news from all angles.
        </p>
        <p class="text-muted mb-2">
          <i-feather class="icon-xs mr-1" name="layout"></i-feather> Easily toggle between political
          parties to take the bias out of your daily reads.
        </p>
        <p class="text-muted">
          <i-feather class="icon-xs mr-1" name="life-buoy"></i-feather> No matter your affiliation; left, middle, or
          right, Unbiased Break is your reliable source to read content that matters to you.
        </p>
        <div class="mt-5">
          <a href="https://app.unbiasedbreak.com" class="btn btn-primary mr-2">Enter App</a>
          <a href="https://play.google.com/store/apps/details?id=com.votercertified.votercertified&hl=en_US&gl=US" class="btn btn-soft-primary">Android</a>
          <a href="https://apps.apple.com/us/app/unbiased-break/id6443419300" class="btn btn-soft-primary" style="margin-left: 6px">Apple</a>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/animations/news.gif" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background: #fff">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> What's the Fuss?</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Unbiased Break is more than a news app; it is meant to
          revolutionize politics. How are we planning to do this?</h3>
        <!-- <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>community</b> & <b>conversion</b> with our suite of <b>social tool</b></h3> -->
        <p class="text-muted font-size-15 mb-4"><b>Phase 1: News & Social Media</b></p>
        <p class="text-muted mb-2">
          <i-feather class="icon-xs mr-1" name="server"></i-feather> This is the current state of the app. You can view
          news articles geared toward your political party of choice. If you want to compare differing viewpoints,
          simply toggle between parties within the same topic. Additionally, you can comment on articles and reply to
          threads. Don't forget to bookmark your favorite news stories for future reference.
        </p>
        <p class="text-muted font-size-15 mb-4"><b>Phase 2: Voter and Political Data</b></p>
        <p class="text-muted mb-2">Users should expect to have a swift and clear overview of their political
          representation. While this phase might not be revolutionary, it is a necessary step on our journey to Phase 3.
        </p>

        <p class="text-muted font-size-15 mb-4"><b>Phase 3: Politicians and Voter Certified</b></p>
        <p class="text-muted mb-2">Phase 3 will provide the world with what we have coined "Voter Certified". More details of this phase will be revealed as we get closer to its release.</p>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-4 mt-lg-0">
          <img src="assets/animations/article.gif" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background: #fff; margin-bottom: 15px">
  <div class="container">
    <div class="row align-items-center" style="justify-content: center;">
      <div class="col-lg-5">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Looking to the Future</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Stay tuned for more news sources and topics!</h3>
        <!-- <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>community</b> & <b>conversion</b> with our suite of <b>social tool</b></h3> -->
        <p class="text-muted mb-2">
          <i-feather class="icon-xs mr-1" name="server"></i-feather> Future updates will include a more user friendly
          commenting experience and ways to share on your socials.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Features End -->