<!-- Footer Start -->
<section class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-5">
          <img src="assets/images/logo.png" alt="" class="" style="width:75%; margin-bottom: 18px">
          <ul class="list-inline footer-social-icon-content">
            <li class="list-inline-item mr-4"><a href="https://twitter.com/UnbiasedBreak" class="footer-social-icon">
                <i-feather name="twitter"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="https://www.instagram.com/unbiasedbreak" class="footer-social-icon">
                <i-feather name="instagram"></i-feather>
              </a></li>
          </ul>
        </div>
      </div>
      
    </div>
  </div>
</section>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">{{year}} © Unbiased Break</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
